import { TopLogo, Paragraph, PrimaryButton, WordsList } from "../../components";
import "./calshare.scss";

const Calshare = () => {
  return (
    <>
      <TopLogo />
      <section className="full-page" id="calshare-page">
        <div id="main-title">
          <img
            src="/assets/CALSHARE S.svg"
            alt="Logo Calshare sur fond orange"
          />
          <Paragraph highlighted="Calshare," id="main-title">
            l'application pour tous tes trajets sportifs.
          </Paragraph>
        </div>
        <div id="goals-illu">
          <div>
            <img
              src="/assets/calshare-goals-illu-01.svg"
              alt="Consulte les événements à venir"
              draggable={false}
            />
            <p>Consulte les événement à venir</p>
            <div className="deco-ball"></div>
          </div>
          <div>
            <img
              src="/assets/calshare-goals-illu-02.svg"
              alt="Propose un trajet de covoiturage"
              draggable={false}
            />
            <p>Propose un trajet</p>
            <div className="deco-ball"></div>
          </div>
          <div>
            <img
              src="/assets/calshare-goals-illu-03.svg"
              alt="Rejoins un trajet de covoiturage"
              draggable={false}
            />
            <p>Rejoins un trajet</p>
            <div className="deco-ball"></div>
          </div>
          <div>
            <img
              src="/assets/calshare-goals-illu-04.svg"
              alt="Rend toi à votre événement !"
              draggable={false}
            />
            <p>Rends-toi à ton événement !</p>
            <div className="deco-ball"></div>
          </div>
        </div>
        <section className="full-page" id="trusted-words">
          <h2>Ils soutiennent le projet :</h2>
          <WordsList />
          <PrimaryButton icon="🖋️" link="/leave-a-print">
            Ajouter mon empreinte
          </PrimaryButton>
        </section>
        <div id="calshare-desc">
          <img
            id="screen"
            src="../assets/iPhone_illu.png"
            alt="Calshare screenshot"
            draggable={false}
          />
          <div>
            <p>
              <span id="first-paragraph">
                Ne te <em>prend plus la tête</em> pour <em>organiser</em> tes
                trajets !
              </span>
              <br />
              <br />
              Utilise Calshare et rends-toi{" "}
              <em>directement sur les lieux de ton événement,</em> en compagnie
              d'autres personnes qui <em>partagent</em> ton{" "}
              <em>enthousiasme.</em>
            </p>
            <div id="stores-badges">
              <a
                href="https://apps.apple.com/fr/app/calshare/id6451190580"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  id="app-store"
                  src="../assets/app-store-badge-white.png"
                  alt="Disponible sur App Store"
                  draggable={false}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=fr.swun.calshare&hl=fr"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  id="google-play"
                  src="../assets/google-play-badge.png"
                  alt="Disponible sur Google Play"
                  draggable={false}
                />
              </a>
            </div>
          </div>
        </div>
        <h1 id="wiki-lore">
          Besoin d'informations sur le fonctionnement de l'app ?
        </h1>
        <PrimaryButton link="wiki" icon="🔎">
          Consulter le Wiki
        </PrimaryButton>
      </section>
    </>
  );
};

export default Calshare;
