import { Title } from "../../../components";

const Notifications = () => {
  return (
    <>
      <Title complement="Wiki Calshare" complementBeforeMainTitle>
        Notifications
      </Title>

      <p>
        Lorsque tu es connecté avec un compte sur l'application, des
        notifications te sont envoyées pour te tenir informé.
      </p>
      <p>
        Tu peux choisir les notifications que tu souhaites reçevoir dans les
        réglages de l'application (accessible depuis la page profil).
      </p>
      <br />
      <p>Il y a plusieurs types de notifications :</p>
      <ul>
        <li>
          <mark>Infos liées à tes trajets.</mark>
          <br />
          <p>
            Ces notifications te sont envoyées pour te tenir à jour sur les
            trajets que tu proposes. Elles t'informent si par exemple, quelqu'un
            réserve sa place sur un de tes trajets, ou à l'inverse s'il
            l'annule.
          </p>
        </li>
        <li>
          <mark>Infos liées aux trajets dont tu fais partie.</mark>
          <br />
          <p>
            Ces notifications contiennent des informations liés aux trajets
            auxquels tu participes. Elles serviront, dans la grande majorité des
            cas, à te prévenir lorsque le conducteur annule son trajet.
          </p>
        </li>
        <li>
          <mark>Nouvelles de tes évènements favoris.</mark>
          <br />
          <p>
            Dans l'application Calshare, il est possible de marquer un évènement
            comme favori.
          </p>
          <p>
            Lorsqu'un utilisateur proposera un trajet pour cet évènement, tu
            seras notifié.
          </p>
        </li>
        <li>
          <mark>Ajout dans un groupe de chat.</mark>
          <br />
          <p>
            Tu reçois ces notifications lorsque tu es ajouté dans un groupe de
            conversation par chat.
          </p>
        </li>
        <li>
          <mark>Messages de chat.</mark>
          <br />
          <p>
            Ces notifications sont, comme leur nom l'indique, celles qui te
            notifient lorsqu'un message est posté dans un groupe de chat.
          </p>
        </li>
      </ul>
    </>
  );
};

export default Notifications;
