import "./title.scss";

interface Props
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "className"> {
  children: React.ReactNode;
  complement?: string;
  complementBeforeMainTitle?: boolean;
  className?: string;
}

const Title = ({
  children,
  complement,
  complementBeforeMainTitle = false,
  className,
  ...props
}: Props) => {
  return (
    <div className={`title ${className}`} {...props}>
      {complement && complementBeforeMainTitle && (
        <h2 id="title-complement">{complement}</h2>
      )}
      <h1 id="title-main">{children}</h1>
      {complement && !complementBeforeMainTitle && (
        <h2 id="title-complement">{complement}</h2>
      )}
    </div>
  );
};

export default Title;
