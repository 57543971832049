import { Title } from "../../../components";

const Profile = () => {
  return (
    <>
      <Title complement="Wiki Calshare" complementBeforeMainTitle>
        Page de profil
      </Title>

      <p>
        Tu peux accéder à la page de ton profil depuis l'icône de droite sur le
        menu de navigation en bas de l'écran.
      </p>
      <p>Sur cette page tu trouveras :</p>
      <ul>
        <li>
          Tes informations personnelles (les informations visibles par les
          autres utilisateurs sont : ta photo, ton pseudonyme et ta biographie
          uniquement)
        </li>
        <li>Le bouton de modification de ton profil</li>
        <li>
          Les paramètres de l'application (notifiations/contact/déconnection et
          suppression du compte)
        </li>
        <li>
          Une section avec :
          <ul>
            <li>Tes trajets favoris</li>
            <li>Les trajets que tu proposes</li>
            <li>Les trajets auxquels tu participes</li>
            <li>Tes évènements favoris</li>
          </ul>
        </li>
      </ul>
    </>
  );
};

export default Profile;
