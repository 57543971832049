interface Props {
  date?: Date;
  children: React.ReactNode;
}

const Content = ({ date, children }: Props) => {
  return (
    <div className="legal-content">
      {date && (
        <h3 id="application-date">
          Version applicable à compter du {date.toLocaleDateString()}
        </h3>
      )}
      <div id="content-main">{children}</div>
    </div>
  );
};

export default Content;
