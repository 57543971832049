import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SearchTrip from "./SearchTrip";
import Home from "./Home";
import "./wiki.scss";
import CreateAccount from "./CreateAccount";
import SubmitTrip from "./SubmitTrip";
import Notifications from "./Notifications";
import Profile from "./Profile";
import Chats from "./Chats";

const Articles = {
  home: {
    name: "Accueil",
    content: <Home />,
  },
  search_trip: {
    name: "Comment rechercher un trajet ?",
    content: <SearchTrip />,
  },
  create_account: {
    name: "Je dois créer mon compte ?",
    content: <CreateAccount />,
  },
  submit_trip: {
    name: "Comment ajouter un trajet ?",
    content: <SubmitTrip />,
  },
  notifications: {
    name: "Préférences de notifi-quoi ?!",
    content: <Notifications />,
  },
  profile: {
    name: "A quoi sert mon profil ?",
    content: <Profile />,
  },
  chats: {
    name: "Les chats ? Miaou ?",
    content: <Chats />,
  },
};

const CalshareWiki = () => {
  const { articleId } = useParams();
  const nav = useNavigate();

  useEffect(() => {
    if (articleId && !Object.keys(Articles).includes(articleId)) {
      nav("/calshare/wiki", {
        replace: true,
      });
    }
  }, [articleId, nav]);

  return (
    <div className="wiki-page">
      <div id="menu-bar-container">
        <div id="menu-bar">
          <h2>Quelle.s interrogation.s as-tu ?</h2>
          <div id="links">
            {Object.entries(Articles).map((val) => (
              <Link
                key={val[0]}
                to={`/calshare/wiki/${val[0]}`}
                className={
                  val[0] === articleId ||
                  (articleId === undefined && val[0] === "home")
                    ? "active"
                    : ""
                }
              >
                {val[1].name}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div id="wiki-content">
        {articleId && Object.keys(Articles).includes(articleId) ? (
          Articles[articleId as keyof typeof Articles].content
        ) : (
          <Home />
        )}
      </div>
    </div>
  );
};

export default CalshareWiki;
