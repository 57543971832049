import React from "react";

interface Props {
  title: string;
  children: React.ReactNode;
}

const Subarticle = ({ title, children }: Props) => {
  return (
    <div className="legal-subarticle">
      <h5 id="subarticle-title">{title}</h5>
      <>{children}</>
    </div>
  );
};

export default Subarticle;
