import { useState } from "react";
import { TopLogo, Loading, PrimaryButton, TextInput } from "../../components";
import "./login.scss";
import { auth, functions } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { httpsCallable } from "firebase/functions";
import { EMAIL_REGEX } from "./Register";

interface Props {
  onAuthenticate?: () => void;
  setLoginOrRegister: (method: "register" | "login") => void;
}

const Login = ({ onAuthenticate, setLoginOrRegister }: Props) => {
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [showPwd, setShowPwd] = useState(false);
  const [forgotPwdSuccess, setForgotPwdSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPwdLoading, setIsForgotPwdLoading] = useState(false);
  const [error, setError] = useState("");

  async function submit() {
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, pwd);
      onAuthenticate?.();
    } catch (e) {
      setIsLoading(false);
      if (e instanceof FirebaseError) {
        if (e.code.includes("invalid-email")) {
          setError("Adresse mail invalide 🙁");
          return;
        }
        if (e.code.includes("user-not-found")) {
          setError(
            "Utilisateur introuvable 🙁, si tu n'as pas de compte, tu peux le créer avec le lien ci-dessous !"
          );
          return;
        }
        if (e.code.includes("wrong-password")) {
          setError(
            "Mot de passe incorrect 🙁, tu n'es pas un pirate j'espère 🤔"
          );
          return;
        }
        setError(e.code.split("/")[1] + "🙁");
      }
    }
    setIsLoading(false);
  }

  async function resetPwd() {
    setIsForgotPwdLoading(true);
    if (!email || !EMAIL_REGEX.test(email)) {
      setError(
        "Veuillez entrer une adresse mail pour renouveler le mot de passe"
      );
      return;
    }
    try {
      await httpsCallable(
        functions,
        "users-forgotPassword"
      )({
        userMail: email,
      });
      setForgotPwdSuccess(true);
    } catch (e) {
      if (e instanceof FirebaseError) {
        if (
          e.message.includes("no-user-found") ||
          e.message.includes("no-user-data")
        ) {
          setError("Aucun compte trouvé");
        }
      }
    }
    setIsForgotPwdLoading(false);
  }

  return (
    <>
      <TopLogo />
      <section className="part-page" id="login-section">
        <div id="main-word">
          <h2>
            <span className="highlighted" id="test">
              Connecte-toi,
            </span>
          </h2>
          <h2>
            Accède à la <span className="highlighted">waiting list</span>
          </h2>
        </div>
        <div id="form-wrapper">
          <TextInput
            type="text"
            label="Adresse mail"
            placeholder="john.doe@exemple.fr"
            value={email}
            onChange={(val) => setEmail(val)}
          />
          <TextInput
            type="password"
            label="Mot de passe"
            placeholder="!unMDP-Bi1-R@buste"
            value={pwd}
            onChange={(val) => setPwd(val)}
            showPwd={showPwd}
            setShowPwd={setShowPwd}
          />
          {isForgotPwdLoading ? (
            <Loading />
          ) : forgotPwdSuccess ? (
            <p id="reset-pwd-success">
              Un mot de passe temporaire va t'être envoyé par mail (cela peut
              prendre plusieurs minutes).
              <br />
              Pense bien à le changer une fois connecté (tu peux le faire dans
              l'application Calshare) !
            </p>
          ) : (
            <p className="link" onClick={resetPwd}>
              J'ai oublié mon mot de passe 😅
            </p>
          )}
        </div>
        {error ? (
          <div id="error-msg">
            <p>Oups ! Huston, on a un problème :</p>
            <p id="error-code">{error}</p>
            <p>
              (Si le problème persiste, merci de nous contacter via{" "}
              <a href="mailto:contact@swun.fr">contact@swun.fr</a>)
            </p>
          </div>
        ) : (
          <></>
        )}
        {isLoading ? (
          <Loading />
        ) : (
          <PrimaryButton icon="🚀" enabled={!!email && !!pwd} onClick={submit}>
            C'est parti !!
          </PrimaryButton>
        )}
        <p className="link" onClick={() => setLoginOrRegister("register")}>
          Créer mon compte
        </p>
      </section>
    </>
  );
};

export default Login;
