import { Title } from "../../../components";

const Chats = () => {
  return (
    <>
      <Title complement="Wiki Calshare" complementBeforeMainTitle>
        Salles de discussion
      </Title>

      <p>
        La section des chats est accessible avec la troisième icône du menu de
        navigation, en partant de la gauche.
      </p>
      <p>
        Tu y trouveras toutes les conversations dans lesquelles tu es impliqué.
      </p>
      <p>
        Une indication te signale si de nouveaux messages ont été envoyés sur le
        groupe depuis ta dernière visite.
      </p>
      <p>
        Lorsque tu cliques sur une conversation, tu accèdes à l'écran des
        messages, d'où tu peux en envoyer également.
      </p>
      <p>
        En haut à droite se trouve un bouton d'informations à propos du chat, tu
        peux y consulter les informations de la conversation.
      </p>
      <p>
        Si tu es un administrateur du chat, tu peux modifier le nom/la photo, tu
        peux promouvoir un autre membre administrateur ou éjecter quelqu'un de
        la conversation (ses messages seront effacés).
      </p>
      <p>
        En bas de cet écran, tu peux quitter la conversation, ou bien la
        supprimer si tu es un administrateur.
      </p>
    </>
  );
};

export default Chats;
