import { ReactNode } from "react";

interface Props {
  highlighted?: string;
  children?: ReactNode;
  id?: string;
  className?: string;
}

const Paragraph = ({ highlighted, children, id, className }: Props) => {
  return (
    <p className={`paragraph ${className}`} id={id}>
      {highlighted ? (
        <span className="first-word">{highlighted + " "}</span>
      ) : (
        <></>
      )}
      {children}
    </p>
  );
};

export default Paragraph;
