import { Link } from "react-router-dom";
import { Title } from "../../../components";

const SubmitTrip = () => {
  return (
    <>
      <Title complement="Wiki Calshare" complementBeforeMainTitle>
        Rechercher un trajet
      </Title>
      <p>
        Pour proposer un trajet, tu dois posséder{" "}
        <Link to={"/calshare/wiki/create_account"}>un compte</Link> et être
        identifié.
      </p>
      <p>
        Tu peux accéder à l'interface de publication d'un trajet depuis la barre
        de menu en bas de l'écran, ou depuis ton profil.
      </p>
      <p>
        Pour publier un trajet tu auras besoin de remplir des renseignements,
        comme :
      </p>
      <ul>
        <li>
          Le lieu de départ (position actuelle par défaut, modifiable à tout
          moment en cliquant sur le champ).
        </li>
        <li>La date de départ (incluant l'heure).</li>
        <li>
          Les lieux où tu souhaites déposer/récupérer des passagers
          (facultatif).
        </li>
        <li>
          L'évènement auquel tu te rends (une heure d'arrivée estimée sera
          calculée à titre indicatif, elle ne prend pas en compte les étapes).
        </li>
        <li>
          Le type de véhicule avec lequel tu te déplaces (voiture ou
          utilitaire).
        </li>
        <li>Le nombre de places à disposition (de 1 à 7).</li>
        <li>
          L'équipement que tu acceptes dans ton véhicule (les dimensions
          moyennes sont spécifiées).
        </li>
        <li>
          Le prix du trajet (ce dernier est calculé par nos soins, tu peux
          cependant l'ajuster de 5€ à la hausse/baisse).
        </li>
      </ul>
      <p>
        Quand tu es prêt, publie ton trajet en cliquant sur le bouton
        correspondant.
      </p>
      <p>
        Tu peux rencontrer certaines erreurs lors du traitement de ta demande :
      </p>
      <ul>
        <li>
          <mark>Des champs sont manquants</mark>
          <p>Vérifie que tu as bien rempli tous les champs obligatoires</p>
        </li>
        <li>
          <mark>Impossible d'associer un évènement au trajet</mark>
          <p>
            Parfois tu as mal rentré l'évènement de destination. Tu es invité à
            rentrer ta destination à nouveau.
          </p>
          <p>
            Si le problème persiste,{" "}
            <a href="mailto:contact@swun.fr">envoie-nous un mail</a>.
          </p>
        </li>
      </ul>
      <br />
      <p>
        Félicitation, ton trajet est publié. Il sera visible par les autres
        utilisateurs et il te sera toujours possible de le supprimer ou de le
        modérer depuis ta{" "}
        <Link to={"/calshare/wiki/profile"}>page de profil.</Link>
      </p>
    </>
  );
};

export default SubmitTrip;
