import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isIOS, isAndroid } from "react-device-detect";

const DownloadRedirect = () => {
  const nav = useNavigate();

  useEffect(() => {
    if (isIOS) {
      window.location.href =
        "https://apps.apple.com/fr/app/calshare/id6451190580";
    } else if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=fr.swun.calshare&hl=fr";
    }
    nav("/calshare");
  });

  return <></>;
};

export default DownloadRedirect;
