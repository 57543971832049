import { ReactNode, useState } from "react";
import {
  AlreadyMember,
  AnimateOnScroll,
  DecoratedSection,
  EnrollButton,
  Paragraph,
  PrimaryButton,
  TopLogo,
  WordsList,
} from "../../components";
import "./home.scss";
import "./sections.scss";
import { Link } from "react-router-dom";

const MainWords = () => {
  return (
    <section className="part-page main-page-words">
      <div id="main-word">
        <h2>
          <span className="highlighted">Ensemble,</span>
        </h2>
        <h2>
          Unissons <span className="highlighted">le sport !</span>
        </h2>
      </div>
    </section>
  );
};

const CalshareLogo = () => {
  const [animatePath, setAnimatePath] = useState(false);
  const [animateStart, setAnimateStart] = useState(false);
  const [animateDest, setAnimateDest] = useState(false);

  return (
    <svg
      id="Calshare_ios"
      data-name="Calshare ios"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 500 500"
    >
      <rect id="bg" width={500} height={500} rx={140} ry={140} />
      <AnimateOnScroll setAnimate={setAnimatePath} selector="#calshare-section">
        <path
          id="S_line_path"
          data-name="S line path"
          className={`stroked stroked-1 ${animatePath ? "drawPath" : ""}`}
          d="M315.2,121.69l-129.77-.17c-35.48,0-64.24,29.46-64.24,64.94s28.76,65.63,64.24,65.63l128.82-.06c35.48,0,64.24,30.15,64.24,65.63s-26.89,64.94-65.63,64.94h-155.01"
        />
      </AnimateOnScroll>
      <AnimateOnScroll setAnimate={setAnimateStart} selector="g#start">
        <g id="start" className={animateStart ? "enterGeneric" : ""}>
          <circle
            className="stroked stroked-2"
            cx="356.28"
            cy="121.52"
            r="27.46"
          />
          <circle className="filled" cx="356.28" cy="121.52" r="17.1" />
        </g>
      </AnimateOnScroll>
      <AnimateOnScroll setAnimate={setAnimateDest} selector="path#dest">
        <path
          id="dest"
          className={`filled ${animateDest ? "enterGeneric" : ""}`}
          d="M148.83,343.89c-3.65-3.73-8.76-6.1-14.55-6.1-13.58,0-23.37,13.02-19.59,26.06l15.14,39.03c1.57,4.06,7.32,4.06,8.9,0l15.14-39.03c2.17-7.48-.13-14.95-5.04-19.97ZM140.41,372.06c-12.7,5.22-24.73-6.81-19.51-19.51,1.35-3.28,3.97-5.9,7.25-7.25,12.7-5.22,24.73,6.81,19.51,19.51-1.35,3.28-3.97,5.9-7.25,7.25Z"
        />
      </AnimateOnScroll>
    </svg>
  );
};

const ProjectDescription = ({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle?: string;
  children: ReactNode;
}) => {
  return (
    <>
      <h2>{title}</h2>
      <p className="subtitle">{subtitle}</p>
      {children}
    </>
  );
};

const Calshare = () => {
  return (
    <DecoratedSection
      className="part-page project-section"
      id="calshare-section"
      decoration={
        <>
          <img
            id="screen"
            src="assets/iPhone_illu.png"
            alt="Calshare screenshot"
            draggable={false}
          />
          <CalshareLogo />
          <a
            href="https://apps.apple.com/fr/app/calshare/id6451190580"
            target="_blank"
            rel="noreferrer"
          >
            <img
              id="app-store"
              src="assets/app-store-badge-white.png"
              alt="Disponible sur App Store"
              draggable={false}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=fr.swun.calshare&hl=fr"
            target="_blank"
            rel="noreferrer"
          >
            <img
              id="google-play"
              src="assets/google-play-badge.png"
              alt="Disponible sur Google Play"
              draggable={false}
            />
          </a>
        </>
      }
    >
      <ProjectDescription
        title="Calshare"
        subtitle="L'app pour tous tes trajets sportifs"
      >
        <p>
          Ne te <em>prend plus la tête</em> pour <em>organiser</em> tes trajets
          !
          <br />
          Utilise Calshare et rend-toi{" "}
          <em>directement sur les lieux de ton événement,</em> en compagnie
          d'autres personnes qui <em>partagent</em> ton <em>enthousiasme.</em>
        </p>
        <Link to="calshare/" className="fancy-link">
          En savoir plus
        </Link>
      </ProjectDescription>
    </DecoratedSection>
  );
};

const FounderWords = () => {
  return (
    <div>
      <h3>
        Notre mission va bien au delà de seulement notre sport. Ils possèdent
        tous des valeurs et des facettes qui résonnent en nous.
        <br />
        Elles ne demandent qu’à être exprimées, renforcées et unies !
      </h3>
      <p>Milo et Anis, fondateurs du projet SWUN</p>
      {/* <div id="images">
        <img src="assets/somn.jpg" alt="Milo, co-fondateur du projet SWUN" />
        <img src="assets/somn.jpg" alt="Milo, co-fondateur du projet SWUN" />
      </div> */}
    </div>
  );
};

const Home = () => {
  const [animateDiscovery, setAnimateDiscovery] = useState(false);

  return (
    <>
      <TopLogo />
      <MainWords />
      <AlreadyMember />
      <section className="full-page" id="goals">
        <Paragraph highlighted="Le projet SWUN,">
          c'est l'ambition de deux pratiquants de Street Workout (a.k.a.
          Callisthénie) de rendre le sport plus plaisant, plus social et plus
          organisé.
        </Paragraph>
        <div id="swun-goals">
          <p id="sentence-start">SWUN existe car</p>
          <div>
            <p className="swun-goal">
              on ne connait pas toujours ses potentiels partenaires de séance
            </p>
            <p className="swun-goal">
              s'organiser pour se rendre à un événement sportif est souvent
              prise de tête
            </p>
            <p className="swun-goal">
              on n'est pas toujours au courant de tous les événements
            </p>
          </div>
        </div>
      </section>
      <EnrollButton />
      <section className="full-page" id="trusted-words">
        <h2>Ils soutiennent le projet :</h2>
        <WordsList />
        <PrimaryButton icon="🖋️" link="/leave-a-print">
          Ajouter mon empreinte
        </PrimaryButton>
      </section>
      <section className="full-page" id="founder-words">
        <FounderWords />
      </section>
      <section className="full-page" id="calshare-section-wrapper">
        <h2 id="projects-title">
          Découvrez{" "}
          <span
            id="highlighted"
            className={animateDiscovery ? "animateDiscovery" : ""}
          >
            l'univers SWUN
          </span>
        </h2>
        <AnimateOnScroll
          setAnimate={setAnimateDiscovery}
          selector="#calshare-section"
          offset={0.5}
        >
          <Calshare />
        </AnimateOnScroll>
      </section>
      <EnrollButton />
    </>
  );
};

export default Home;
