import { Link } from "react-router-dom";

const TopLogo = () => {
  return (
    <Link to={"/"} id="top-logo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2000 100"
        fill="none"
      >
        <path d="M903.1,75.75H0s0-9.74,0-9.74h905.6c5.34-4.62,2.1-10.54-2.5-10.54h-16.47c-2.02,0-3.99-.4-5.84-1.18-1.79-.76-3.39-1.84-4.77-3.21s-2.46-2.98-3.21-4.77c-.78-1.85-1.18-3.82-1.18-5.84s.4-3.99,1.18-5.84c.76-1.79,1.84-3.39,3.21-4.77s2.98-2.46,4.77-3.21c1.85-.78,3.82-1.18,5.84-1.18h28.69l2.83,9.74h-34.02c-5.34,4.62-2.1,10.54,2.5,10.54h16.47c2.02,0,3.99.4,5.84,1.18,1.79.76,3.39,1.84,4.77,3.21,1.38,1.38,2.46,2.98,3.21,4.77.78,1.85,1.18,3.82,1.18,5.84s-.4,3.99-1.18,5.84c-.76,1.79-1.84,3.39-3.21,4.77-1.38,1.38-2.98,2.46-4.77,3.21-1.85.78-3.82,1.18-5.84,1.18Z" />
        <path d="M988.6,61.98c2.18,5.15,5.28,9.78,9.21,13.76h-35.16l-7.88-17.16-8.15,17.16-11.54-.04-13.87-50.29h11.56l9.63,34.9,6.65-14h11.67l6.14,13.37,9.44-34.23h8.86v19.41c0,5.93,1.16,11.69,3.46,17.11Z" />
        <path d="M1120.32,51.04v14.96h879.68v9.58h-891.24v-24.54c0-4.04-1.57-7.84-4.43-10.7-2.86-2.86-6.66-4.43-10.7-4.43s-7.84,1.57-10.7,4.43c-2.86,2.86-4.43,6.66-4.43,10.7v22.54c-3.19,0-6.08-1.29-8.17-3.39-1.17-1.17-2.09-2.59-2.67-4.17h0c.72-1.32,1.38-2.67,1.97-4.07,2.3-5.43,3.46-11.18,3.46-17.11v-2.73c0-5.22-1.08-10.34-3.13-15.05,2.11-2.32,5.16-3.77,8.54-3.77v5.75c1.48-1.02,3.07-1.89,4.74-2.6,3.29-1.39,6.79-2.1,10.39-2.1s7.1.71,10.39,2.1c3.18,1.34,6.03,3.27,8.48,5.72,2.45,2.45,4.37,5.3,5.72,8.48,1.39,3.29,2.1,6.79,2.1,10.39Z" />
        <path d="M1057.9,20.65c5.82,5.99,9.03,14.05,9.03,22.4v2.35c0,5.11-1,10.07-2.98,14.74-1.91,4.51-4.64,8.56-8.11,12.03-3.47,3.47-7.52,6.2-12.03,8.11-4.67,1.98-9.63,2.98-14.73,2.98v.02h-.02c-5.11,0-10.07-1-14.74-2.98-4.51-1.91-8.56-4.64-12.03-8.11-3.47-3.47-6.2-7.52-8.11-12.03-1.98-4.67-2.98-9.63-2.98-14.74V13.97h17.38v31.45c0,11.29,9.18,20.48,20.47,20.48v-.02h.02c11.29,0,20.48-9.19,20.48-20.48V14.07c2.95,1.74,5.65,3.85,8.05,6.27.1.1.2.21.31.31Z" />
        <text className="cls-1" transform="translate(1108 100)">
          PROJECT
        </text>
      </svg>
    </Link>
  );
};

export default TopLogo;
