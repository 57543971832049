import { Title } from "../../../components";

const Home = () => {
  return (
    <>
      <Title complement="Bienvenue sur le" complementBeforeMainTitle>
        Wiki Calshare
      </Title>

      <p className="wiki-article-content">
        Retrouve ici toutes les informations que tu as besoin d'avoir pour
        utiliser tout le potentiel de l'application !
      </p>
    </>
  );
};

export default Home;
