interface Props {
  title: string;
  children: React.ReactNode;
}

const Article = ({ title, children }: Props) => {
  return (
    <div className="legal-article">
      <h4 id="article-title">{title}</h4>
      <>{children}</>
    </div>
  );
};

export default Article;
