import { Title, TopLogo } from "../../../components";
import { Article, Content, Subarticle } from "../../../components/Legal";
import "./privacy_policy.scss";

const PrivacyPolicy = () => {
  return (
    <>
      <TopLogo />

      <Title complement="Calshare" className="legal-title">
        Politique de Confidentialité
      </Title>

      <Content date={new Date("2023-11-01")}>
        <Article title="1. Généralités">
          <p>
            CalShare est le nom donné à cette application créée, gérée et
            traitée par l’Association SWUN (dont le siège social est situé au 5,
            avenue de la Georges Pompidou, 69800 Saint - Priest, France). En sa
            qualité de responsable du traitement, SWUN attache une grande
            importance à la protection et au respect de votre vie privée. La
            présente politique (la « Politique de Confidentialité ») vise à vous
            informer de nos pratiques concernant la collecte, l’utilisation et
            le partage des informations que vous êtes amenés à nous fournir par
            le biais de notre plateforme (la « Plateforme ») accessible depuis
            le site internet{" "}
            <a href="/" target="_blank" rel="noreferrer">
              swun.fr
            </a>{" "}
            ou nos applications mobiles. SWUN sera désignée par la suite en tant
            que « SWUN », « nous » ou « notre ». En ce qui concerne d’autres
            responsables en charge de certaines activités de traitement,
            veuillez vous référer à l’article 4 ci-dessous.
            <br />
            <br />
            Cette Politique de Confidentialité (ainsi que nos{" "}
            <a
              href="https://swun.fr/assets/CGU_Calshare.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Conditions générales d’utilisation
            </a>{" "}
            et tout document auquel il y est fait référence) présente la manière
            dont nous traitons les données personnelles que nous recueillons et
            que vous nous fournissez. Nous vous invitons à lire attentivement le
            présent document pour connaître et comprendre nos pratiques quant
            aux traitements de vos données personnelles que nous mettons en
            oeuvre.
          </p>
        </Article>
        <Article title="2. Les informations que nous recueillons">
          <p>
            Nous sommes susceptibles de recueillir et de traiter les données
            suivantes :
          </p>
          <Subarticle title="2.1. Les informations que vous nous transmettez directement">
            <p>
              En utilisant notre Plateforme, vous êtes amenés à nous transmettre
              des informations, dont certaines sont de nature à identifier vous
              ou les passagers pour lesquels vous effectuez des réservations («
              Données Personnelles »). C’est notamment le cas lorsque vous
              remplissez des formulaires (comme par exemple le formulaire
              d’inscription), lorsque vous participez à l’un de nos jeux,
              concours, offres promotionnelles, études ou sondages, lorsque vous
              nous contactez – que ce soit par téléphone, email ou tout autre
              moyen de communication – ou lorsque vous nous faites part d’un
              problème concernant l’utilisation de notre Plateforme. Ces
              informations contiennent notamment les données suivantes :
            </p>

            <p>
              <strong>2.1.1.</strong> Les données nécessaires à l’inscription au
              service que nous fournissons sur notre Plateforme. Ces données
              sont notamment un pseudo, une adresse e-mail, et, lorsque vous
              créez un compte, un mot de passe. Ces informations sont
              obligatoires. À défaut, SWUN ne sera pas en mesure de vous fournir
              les services proposés par notre Plateforme ou, le cas échéant,
              vous ne serez pas autorisés à créer de compte sur notre Plateforme
              ;
            </p>

            <p>
              <strong>2.1.2.</strong> Une photographie publiée sur votre profil
              ;
            </p>

            <p>
              <strong>2.1.3.</strong> Des informations relatives à votre
              véhicule ;
            </p>

            <p>
              <strong>2.1.4.</strong> Votre mini-biographie ;
            </p>

            <p>
              <strong>2.1.5.</strong> Une copie de tous les échanges écrits
              intervenus entre vous et SWUN ; nous sommes susceptibles
              d’enregistrer les conversations par e-mail entre vous et SWUN (par
              exemple, avec les agents de notre service Contact). Veuillez nous
              le signaler si vous désirez que l’échange ne soit pas conservé ;
            </p>

            <p>
              <strong>2.1.6.</strong> Une copie de l’ensemble des réservations
              ou des publications effectuées sur notre Plateforme ;
            </p>

            <p>
              <strong>2.1.7.</strong> Le détail des opérations financières ou
              comptables effectuées sur notre Plateforme ou par tout autre
              moyen, contenant notamment les informations relatives aux trajets
              réservés ou pour lesquels vous avez publié une annonce sur notre
              Plateforme. Ces informations peuvent inclure vos préférences de
              voyage ainsi que vos positions de départ ;
            </p>

            <p>
              <strong>2.1.8.</strong> Le détail de vos visites sur notre
              Plateforme et des contenus auxquels vous avez accédé ;
            </p>

            <p>
              <strong>2.1.9.</strong> Le cas échéant, vos réponses à nos
              sondages et questionnaires et les avis que vous avez laissés pour
              évaluer un trajet effectué par le biais de notre Plateforme, ces
              informations étant notamment susceptibles d’être utilisées dans le
              cadre de recherches et d’analyses du comportement utilisateur ;
            </p>

            <p>
              <strong>2.1.10.</strong> Les données que nous pouvons vous
              demander de fournir lorsque vous nous signalez un problème relatif
              à notre Plateforme ou à nos services, comme par exemple l’objet de
              votre demande d’assistance ;
            </p>

            <p>
              <strong>2.1.11.</strong> Les données liées à votre localisation
              lorsque vous avez accepté que nous collections et traitions ces
              données afin que nous puissions répondre à votre recherche de
              trajets proches de vous ;
            </p>
          </Subarticle>
          <Subarticle title="2.2. Les données que nous recueillons automatiquement">
            <p>
              <strong>2.2.1.</strong> Dans le cas où vous vous connectez à nos
              services en utilisant les fonctionnalités de réseaux sociaux mises
              à votre disposition, SWUN aura accès à certaines des données
              (notamment, vos prénom, nom de famille, photographie et adresse
              e-mail) de votre compte sur ledit réseau social conformément aux
              conditions générales d’utilisation du réseau social concerné ;
            </p>

            <p>
              <strong>2.2.2.</strong> Lors de chacune de vos visites, nous
              sommes susceptibles de recueillir, conformément à la législation
              applicable et avec votre accord, le cas échéant, des informations
              relatives aux appareils sur lesquels vous utilisez nos services ou
              aux réseaux depuis lesquels vous accédez à nos services, tels que
              notamment vos adresses IP, données de connexion, types et versions
              de navigateurs internet utilisés, types et versions des plugins de
              votre navigateur, systèmes et plateformes d’exploitation, données
              concernant votre parcours de navigation sur notre Plateforme,
              notamment votre parcours sur les différentes pages URL de notre
              Plateforme, le contenu auquel vous accédez ou que vous consultez,
              les termes de recherches utilisés, les erreurs de téléchargement,
              la durée de consultation de certaines pages, l’identifiant
              publicitaire de votre appareil, les interactions avec la page
              ainsi que tout numéro du téléphone utilisé pour nous contacter.
            </p>

            <p>
              <strong>2.2.3.</strong> Nous recueillons également des
              informations sur votre utilisation de notre Plateforme qui sont
              susceptibles d’être affichées sur votre profil public, lorsque
              vous en avez un.
            </p>
          </Subarticle>
          <Subarticle title="2.3. Durée de conservation de vos données">
            <p>
              <strong>2.3.1.</strong> À l’exception des catégories de Données
              Personnelles visées aux articles 2.3.2. et 2.3.3. ci-dessous, vos
              Données Personnelles sont archivées à l’issue des périodes
              suivantes :
              <ul>
                <li>
                  <p>
                    (i) 5 ans après votre dernière utilisation de notre
                    Plateforme, si vous n’avez pas fermé votre compte;
                  </p>
                </li>
                <li>
                  <p>
                    (ii) 30 jours après la fermeture de votre compte, sauf si
                    vous avez reçu un avis négatif ou un signalement, auquel cas
                    vos données sont conservées pendant 2 ans suivant la
                    fermeture de votre compte.
                  </p>
                </li>
              </ul>
            </p>

            <p>
              <strong>2.3.2.</strong> Les catégories de Données Personnelles
              suivantes sont susceptibles d’être conservées pour des durées
              différentes :
              <ul>
                <li>
                  <p>
                    (i) Les données financières (par exemple les paiements,
                    remboursements, etc.) sont conservées pour la durée requise
                    par les lois applicables en matière fiscale et comptable
                    (par exemple, les documents et pièces comptables sont
                    conservés jusqu’à 10 ans) ;
                  </p>
                </li>
                <li>
                  <p>
                    (ii) Les contenus créés par vous sur notre Plateforme (tels
                    que les commentaires, avis et notations) sont rendus
                    anonymes passés les délais mentionnés au 2.3.1 mais
                    demeurent visibles sur notre Plateforme ;
                  </p>
                </li>
                <li>
                  <p>
                    (iii) Les données de journalisation sont conservées jusqu’à
                    12 mois à compter de leur collecte ;
                  </p>
                </li>
                <li>
                  <p>
                    (iv) Les conversations par e-mail avec notre service Contact
                    sont conservées jusqu’à 1 an ;
                  </p>
                </li>
              </ul>
            </p>

            <p>
              <strong>2.3.3.</strong> Dans l’hypothèse où votre compte a été
              suspendu ou bloqué, nous conservons vos données pour une durée de
              2 ou 10 ans selon la gravité de la violation à compter de la
              suspension afin d’éviter tout contournement de votre part des
              règles en vigueur sur notre Plateforme ;
            </p>

            <p>
              <strong>2.3.4.</strong> Ensuite, nous pouvons conserver en
              archivage certaines de vos Données Personnelles pour une durée
              n’excédant pas cinq (5) ans afin de respecter nos obligations
              légales et pour la gestion d’éventuels litiges.
            </p>
          </Subarticle>
        </Article>
        <Article title="3. Comment utilisons-nous les données que nous recueillons ?">
          <table>
            <tr>
              <th>FINALITÉS</th>
              <th>FONDEMENT LÉGAL</th>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.1.</strong> Exécuter les contrats conclus
                  <ul>
                    <li>
                      <p>entre vous et nous</p>
                    </li>
                    <li>
                      <p>
                        entre nous et nos partenaires commerciaux (par exemple
                        en matière d’assurance)
                      </p>
                    </li>
                  </ul>
                  <p>et vous fournir les informations et services demandés ;</p>
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est nécessaire à l’exécution de nos obligations
                  contractuelles respectives.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.2.</strong> Vous envoyer les documents et
                  informations nécessaires à l’utilisation de nos services
                  (comme par exemple, les confirmations de réservation) par
                  e-mail, SMS ou tout autre moyen de communication ;
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est nécessaire à l’exécution de nos obligations
                  contractuelles respectives.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.3.</strong> Percevoir vos paiements ou vous
                  transmettre les sommes collectés pour votre compte ;
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est nécessaire à l’exécution de nos obligations
                  contractuelles respectives.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.4.</strong> Vous permettre de sauvegarder les
                  informations relatives à votre carte de paiement pour vos
                  futurs transactions sur la plateforme ;
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est réalisé sur la base de votre consentement.
                  Vous pouvez à tout moment retirer votre consentement en
                  supprimant vos informations dans votre profil ou bien en
                  contactant notre Service Client.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.5.</strong> Vous permettre de personnaliser votre
                  profil sur notre Plateforme ;
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est réalisé sur la base de votre consentement.
                  Vous pouvez à tout moment retirer votre consentement en
                  supprimant vos informations dans votre profil ou bien en
                  contactant notre Service Client.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.6.</strong> Vous permettre de communiquer et
                  d’échanger avec les autres membres de notre communauté sur la
                  Plateforme, notamment en ce qui concerne nos services ou le(s)
                  trajet(s) que vous prévoyez d’effectuer ;
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est nécessaire à l’exécution de nos obligations
                  contractuelles respectives.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.7.</strong> Vous donner accès et vous permettre de
                  communiquer avec notre Service Client ;
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est nécessaire à l’exécution de nos obligations
                  contractuelles respectives, ou nécessaire à la constatation,
                  l’exercice ou la défense d’un droit en justice.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.8.</strong> Améliorer notre Service Client et former
                  nos conseillers clientèle par l’enregistrement de vos
                  conversations téléphoniques avec nous ;
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est fondé sur notre intérêt légitime (vous
                  fournir un support client de qualité/améliorer notre support
                  client). Vous pouvez vous opposer à ce traitement lors de
                  chaque contact.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.10.</strong> Vous envoyer par e-mail, SMS ou tout
                  autre moyen de communication, conformément aux dispositions
                  légales applicables et avec votre accord lorsque la
                  législation l’exige, des messages marketing, publicitaires et
                  promotionnels, ou vous suggérer et vous conseiller des biens
                  ou des services susceptibles de vous intéresser. Nous sommes
                  également susceptibles d’utiliser vos données pour vous
                  adresser des messages publicitaires susceptibles de vous
                  intéresser sur les plateformes de réseaux sociaux ou sites de
                  tiers ou pour créer des listes d’exclusion aux messages
                  publicitaires. Si vous souhaitez davantage d’informations à ce
                  sujet, nous vous invitons à prendre connaissance des documents
                  contractuels de ces plateformes ou sites ;
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est fondé sur votre consentement, vous pouvez à
                  tout moment retirer votre consentement à recevoir de la
                  prospection commerciale en modifiant vos préférences dans
                  votre profil, cliquant sur le lien de désinscription fourni
                  dans chacune de nos communications ou en contactant le Service
                  Client,;
                  <br />
                  ou notre intérêt légitime (vous proposer des publicités
                  pertinentes), vous disposez dans ce cas d’un droit
                  d’opposition.
                  <br />
                  <br />
                  Pour en savoir plus, veuillez consulter l’article 7 Publicité
                  ciblée, e-mails et SMS que nous vous envoyons.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.11.</strong> Afin de vous informer, par e-mail, SMS
                  ou tout autre moyen de communication, des modifications
                  apportées à nos services et vous accompagner dans
                  l’utilisation de nos services ;
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est nécessaire à l’exécution de nos obligations
                  contractuelles respectives, au respect de nos obligations
                  légales, à la constatation, l’exercice ou la défense d’un
                  droit en justice ou sur la base de notre intérêt légitime
                  (vous fournir des informations pertinentes). Dans ce cas, vous
                  pouvez vous opposer à ce traitement lors de chaque contact.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.12.</strong> Vérifier l’identité alléguée et/ou les
                  informations contenues dans votre passeport, permis de
                  conduire, carte d’identité ou tout autre document d’identité
                  que nous aurions collecté lors de votre inscription ou votre
                  utilisation de notre Plateforme ;
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est fondé sur votre consentement dans le cadre
                  de l’obtention du badge Profil Vérifié (dans ce cas, vous
                  pouvez retirer votre consentement à tout moment en contactant
                  le Service Client), nécessaire au respect de nos obligations
                  légales ou nécessaire à la constatation, l’exercice ou la
                  défense d’un droit en justice.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.13.</strong> Gérer notre Plateforme et effectuer des
                  opérations techniques internes dans le cadre de résolution de
                  problèmes, analyse de données, de tests, recherches ;
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est fondé sur notre intérêt légitime (assurer la
                  sécurité de notre Plateforme et en améliorer leurs
                  caractéristiques).
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.14.</strong> Suivre la relation clients et notamment
                  réaliser (par mails, appels téléphoniques ou SMS) des enquêtes
                  de satisfaction et d’études comprenant les sondages, les tests
                  produits et les statistiques ;
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est fondé sur notre intérêt légitime (évaluer
                  notre Plateforme et les services que nous fournissons afin de
                  les améliorer). Vous pouvez vous opposer à ce traitement lors
                  de la prise de contact.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.15.</strong> Améliorer et optimiser notre
                  Plateforme, notamment pour nous assurer que l’affichage de nos
                  contenus est adapté à votre appareil ;
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est fondé sur notre intérêt légitime (vous
                  fournir des contenus pertinents).
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.16.</strong> Vous permettre d’utiliser les
                  fonctionnalités interactives de nos services si vous le
                  souhaitez, comme faire des recherches de trajets autour de
                  vous en fonction de votre localisation ou publier des trajets
                  selon votre localisation;
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est nécessaire à l’exécution de nos obligations
                  contractuelles respectives ou réalisé avec votre consentement
                  lorsque la législation l’exige. Dans ce cas, vous pouvez
                  retirer votre consentement en désactivant les permissions
                  d’accès directement sur le système d’exploitation de votre
                  téléphone.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.17.</strong> Nous aider à la préservation d’un
                  environnement sain et sécurisé sur notre Plateforme, notamment
                  en luttant contre la fraude ; et
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est fondé sur notre intérêt légitime (lutter
                  contre la fraude et assurer la sécurité de nos plateformes),
                  nécessaire au respect de nos obligations légales ou nécessaire
                  à la constatation, l’exercice ou la défense d’un droit en
                  justice.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>3.18.</strong> Évaluer l’efficacité des messages
                  publicitaires que nous adressons et les adapter à nos
                  utilisateurs.
                </p>
              </td>
              <td>
                <p>
                  Ce traitement est fondé sur notre intérêt légitime (mesurer et
                  optimiser l’efficacité de nos campagnes publicitaires) ou sur
                  votre consentement, lorsque la législation l’exige (Vous êtes
                  libre de changer d’avis à tout moment et d’ajuster les
                  paramétrages des cookies ultérieurement. Il vous suffit de
                  vous rendre sur les paramètres des cookies qui sont affichés
                  dans le pied de page ou dans les paramètres de votre compte
                  CalShare).
                </p>
              </td>
            </tr>
          </table>
        </Article>
        <Article
          title="4. Qui sont les destinataires des informations que nous recueillons et
          pourquoi leur transmettons nous ces informations ?"
        >
          <p>
            <strong>4.1.</strong> Dans le cadre de l’utilisation de nos
            services, certaines de vos informations sont transmises aux autres
            membres de notre communauté distribués sur notre Plateforme par
            l’intermédiaire de votre profil public ou dans le cadre du processus
            de réservation ;
          </p>

          <p>
            <strong>4.2.</strong> Nous sommes également susceptibles de partager
            des informations vous concernant, notamment des Données
            Personnelles, avec d’autres entités du groupe SWUN dans le cadre
            prévu par la présente Politique de Confidentialité. Dans l’hypothèse
            où nous vendrions ou acquérions une entreprise ou des actifs, nous
            nous réservons la possibilité de partager vos Données Personnelles
            avec le potentiel vendeur ou acheteur de cette entreprise ou de ces
            actifs. Si SWUN ou tout ou partie de ses actifs sont rachetés par un
            tiers, les données en notre possession seront, le cas échéant,
            transférées au nouveau propriétaire.
          </p>

          <p>
            <strong>4.3.</strong> Nous pouvons divulguer des informations vous
            concernant aux tribunaux, aux autorités policières, aux douanes, aux
            autorités gouvernementales ou publiques, aux autorités fiscales, ou
            à des tiers autorisés, si et dans la mesure où nous avons
            l’obligation légale de le faire ou si nous pensons de bonne foi que
            cela est nécessaire pour (i) répondre à toute réclamation à
            l’encontre de SWUN, (ii) se conformer à toute demande judiciaire,
            (iii) faire exécuter tout contrat conclu avec nos membres, tel que
            les Conditions Générales d’Utilisation et la présente Politique de
            Confidentialité (iv) en cas d’urgence mettant en jeu la santé
            publique ou l’intégrité physique d’une personne, (v) dans le cadre
            d’enquêtes et d’investigations, ou (vi) afin de garantir les droits,
            les biens et la sécurité de SWUN, ses membres et plus généralement
            tout tiers.
          </p>

          <p>
            <strong>4.4.</strong> Nous travaillons également en étroite
            collaboration avec des entreprises tierces qui peuvent avoir accès à
            vos Données Personnelles, et notamment avec :
            <ul>
              <li>
                <p>
                  (i) les plateformes de réseaux sociaux qui peuvent vous
                  proposer des fonctionnalités vous permettant d’intégrer dans
                  votre profil SWUN des informations issues de votre compte sur
                  leur propre plateforme ;
                </p>
              </li>
              <li>
                <p>
                  (ii) nos partenaires commerciaux qui font la promotion de
                  leurs services sur notre Plateforme et auxquels vous pouvez
                  décider de souscrire. Ces services peuvent notamment consister
                  en des services d’assurance, des services bancaires, des
                  services de location de véhicules, etc ;
                </p>
              </li>
              <li>
                <p>
                  (iii) nos partenaires commerciaux sur le site internet
                  desquels nous pouvons éventuellement faire de la publicité
                  pour nos services ;
                </p>
              </li>
              <li>
                <p>
                  (iv) les sous-traitants auxquels nous recourons notamment en
                  matière de prestations techniques, signature électronique,
                  services de paiement, vérification d’identité et permis de
                  conduire, centres de relation client ou encore les
                  fournisseurs de solutions analytiques.
                </p>
              </li>
            </ul>
          </p>

          <p>
            <strong>4.5.</strong> Nous ne partageons vos données avec les tiers
            mentionnés au 4.4. ci-dessus que dans les cas suivants :
          </p>

          <p>
            <strong>4.5.1.</strong> Lorsque nous faisons appel à un prestataire
            de services dans le cadre de l’exécution de tout contrat conclu
            entre vous et nous ou afin de fournir ou améliorer nos services (par
            exemple dans le cadre des paiements effectués via notre Plateforme)
            ;
          </p>

          <p>
            <strong>4.5.2.</strong> Dans le cadre du processus de réservation,
            nous sommes amenés, afin de fournir les services demandés, à
            afficher sur notre Plateforme ou à transmettre à un autre membre
            certaines de vos Données Personnelles telles que vos prénom,
            photographie, numéro de téléphone portable ou adresse e-mail ;
          </p>

          <p>
            <strong>4.5.3.</strong> Nous publions les avis que vous rédigez dans
            le cadre de notre système d’avis sur notre Plateforme. Ces avis, qui
            contiennent votre pseudonyme ainsi que votre photographie sont
            visibles par tous les visiteurs de notre Plateforme ;
          </p>

          <p>
            <strong>4.5.4.</strong> Lorsque nous faisons appel à des
            fournisseurs de moteurs de recherche et de solutions analytiques
            pour améliorer et optimiser notre Plateforme ; nous pouvons
            également être amenés à partager avec nos partenaires en matière
            d’assurance des informations qui nous permettent d’évaluer et
            améliorer la performance de nos produits d’assurance et optimiser
            leur conception ;
          </p>

          <p>
            <strong>4.5.5.</strong> Lorsque vous en faites la demande expresse
            (par exemple lorsque vous utilisez les méthodes d’authentification
            fournies par les réseaux sociaux, souscrivez à un service fourni par
            un de nos partenaires, demandez à bénéficier d’une offre ou d’un bon
            plan d’un de nos partenaires) ;
          </p>

          <p>
            <strong>4.5.6.</strong> Lorsque nous diffusons, sur les sites
            internet de nos partenaires commerciaux, certaines parties de notre
            Plateforme (dont les trajets que vous avez postés) par
            l’intermédiaire d’API ou de widgets. Dans ce cas, certaines
            informations issues de votre profil public pourront également être
            affichées sur ces sites.
          </p>

          <p>
            <strong>4.6.</strong> Conformément à la législation applicable et
            avec votre consentement lorsqu’il est requis, nous pouvons agréger
            des données qui vous concernent et que nous recevons ou envoyons à
            nos partenaires commerciaux, notamment tout ou partie de vos Données
            Personnelles et les informations collectées par l’intermédiaire de
            cookies. Ces informations agrégées ne seront utilisées que pour les
            finalités décrites ci-dessus.
          </p>

          <p>
            <strong>4.7.</strong> Nous attirons votre attention sur le fait que
            si vous décidez de nous laisser accéder à certaines de vos
            informations, notamment à vos Données Personnelles, par
            l’intermédiaire de services de connexion mis à disposition par nos
            partenaires commerciaux, leurs politiques de confidentialité vous
            est également opposable. Nous n’avons aucun contrôle sur la collecte
            ou le traitement de vos données mis en oeuvre par nos partenaires
            commerciaux sur leur propre plateforme.
          </p>
        </Article>
        <Article title="5. Comment utilisons-nous et modérons nous vos messages ?">
          <p>
            <strong>5.1.</strong> Nous pouvons prendre connaissance des messages
            que vous échangez avec d’autres membres de notre communauté via
            notre Plateforme notamment à des fins de prévention des fraudes,
            d’amélioration de nos services, d’assistance utilisateur, de
            vérification du respect par nos membres des contrats conclus avec
            nous et notamment de nos Conditions Générales d’Utilisation. Par
            exemple, afin d’éviter qu’un membre ne contourne notre système de
            réservation en ligne, nous nous autorisons à parcourir et analyser
            les messages échangés sur notre Plateforme afin de s’assurer qu’ils
            ne contiennent aucune coordonnée ou référence à d’autres sites
            internet et, le cas échéant, cela peut donner lieu à un blocage de
            ces messages ou filtrage de tout ou partie de ces messages.
          </p>

          <p>
            <strong>5.2.</strong> Nous ne prenons jamais connaissance de vos
            communications avec d’autres membres de notre communauté à des fins
            promotionnelles ou de ciblage publicitaire. Lorsque cela est
            possible, nous avons recours à des systèmes automatisés pour
            procéder à la modération des messages transmis entre les membres via
            notre Plateforme, sans qu’aucune décision à portée individuelle ne
            soit prise.
          </p>
        </Article>
        <Article title="6. Vos données sont-elles transférées, comment et où ?">
          <p>
            En règle générale, nous conservons vos Données Personnelles au sein
            de l’Union Européenne. Cependant, dans la mesure où, par exemple,
            certains de nos prestataires de service sont situés dans des pays en
            dehors de l’Union Européenne (« Pays Tiers »), nous transférons
            certaines de vos Données Personnelles dans des Pays Tiers. Cela peut
            notamment être le cas vers des Pays Tiers pour lesquels la
            Commission Européenne n’a pas pris une décision de « protection
            adéquate ». Dans un tel cas, nous nous assurons que ce transfert
            soit effectué en conformité avec la réglementation applicable et
            garantisse un niveau de protection suffisant de la vie privée et des
            droits fondamentaux des personnes (notamment par les clauses
            contractuelles types de la Commission Européenne). Sur simple
            demande formulée à notre Service de Contact (
            <a href="mailto:contact@swun.fr">contact@swun.fr</a>), nous pouvons
            vous fournir davantage d’informations quant à ces garanties
            (notamment les clauses contractuelles types de la Commission
            Européenne).
          </p>
        </Article>
        <Article title="7. Quels sont vos droits sur vos données personnelles ?">
          <p>
            <strong>7.1.</strong> Vous disposez du droit de recevoir une copie
            de vos Données Personnelles en notre possession (« droit d’accès »).
          </p>

          <p>
            <strong>7.2.</strong> Vous pouvez également demander l’effacement de
            vos Données Personnelles ainsi que la rectification des Données
            Personnelles erronées ou obsolètes (« droit d’effacement et droit de
            rectification »). Veuillez noter que nous sommes susceptibles de
            conserver certaines informations vous concernant lorsque la loi nous
            l’impose ou lorsque nous avons un motif légitime de le faire. C’est
            par exemple le cas, si nous estimons que vous avez commis une fraude
            ou violé nos Conditions Générales d’Utilisation et que nous
            souhaitons éviter que vous contourniez les règles applicables à
            notre communauté.
          </p>

          <p>
            <strong>7.3.</strong> Vous disposez également du droit de vous
            opposer à tout moment pour des raisons tenant à votre situation
            particulière, (i) au traitement de vos Données Personnelles à des
            fins de marketing direct ou (ii) à d’autres traitements effectués
            sur le fondement de notre intérêt légitime (« droit d’opposition »).
          </p>

          <p>
            <strong>7.4.</strong> Vous disposez du droit de limiter les
            traitements effectués sur vos Données Personnelles (« droit à la
            limitation »). Veuillez noter que ce droit ne s’applique que si (i)
            vous contestez l’exactitude de vos Données Personnelles pendant la
            durée nous permettant de vérifier l’exactitude de ces dernières;
            (ii) en cas de traitement illicite de notre part et que vous exigez
            une limitation de leur utilisation plutôt qu’un effacement, (iii)
            nous n’avons plus besoin des données à caractère personnel aux fins
            du traitement mais celles-ci vous sont encore nécessaires pour la
            constatation, l’exercice ou la défense de droits en justice; iv) en
            cas d’exercice de votre droit d’opposition pendant la durée de
            vérification portant sur le point de savoir si les motifs légitimes
            que nous poursuivons prévalent les votre.
          </p>

          <p>
            <strong>7.5.</strong> Vous disposez également du droit à la
            portabilité de vos données, c’est-à-dire au droit de recevoir les
            Données Personnelles que vous nous avez fournis dans un format
            structuré, couramment utilisé et lisible par la machine et le droit
            de transmettre ces données à un autre responsable du traitement («
            droit à la portabilité »).
          </p>

          <p>
            <strong>7.6.</strong> Vous disposez du droit d’introduire une
            réclamation auprès de l’autorité de contrôle compétente ou d’obtenir
            réparation auprès des tribunaux compétents si vous considérez que
            nous n’avons pas respecté vos droits.
          </p>

          <p>
            <strong>7.7.</strong> Vous disposez également du droit de définir
            des directives relatives au sort de vos Données Personnelles après
            votre mort.
          </p>

          <p>
            <strong>7.8.</strong> Pour exercer ces droits, vous pouvez contacter
            notre Service de contact selon les modalités définies à l’article 11
            ci-dessous.
          </p>

          <p>
            <strong>7.9.</strong> Pour exercer vos droits dans le cadre de
            l’utilisation de la base de données produite par notre société : sur
            justificatif de votre identité et si les conditions d’exercice de
            ces droits sont remplies, vous pouvez exercer vos droit d’accès,
            droit de rectification, droit d’effacement, droit à la limitation
            des traitements, droit d’opposition, droit d’introduire une
            réclamation auprès de la CNIL et votre droit de définir le sort de
            vos données après votre décès en écrivant à SWUN (
            <a href="mailto:contact@swun.fr">contact@swun.fr</a> ou DJELLOUL –
            5, avenue Georges Pompidou – 69800 Saint - Priest) ou en nous
            contactant directement (voir article 11 ci-dessous).
          </p>
        </Article>
        <Article title="8. Confidentialité de votre mot de passe">
          <p>
            Vous êtes responsable de la confidentialité du mot de passe que vous
            avez choisi pour accéder à votre compte sur notre Plateforme. Vous
            vous engagez à conserver ce mot de passe secret et à ne le
            communiquer à personne.
          </p>
        </Article>
        <Article title="9. Liens vers d’autres sites internet et réseaux sociaux">
          <p>
            Notre Plateforme peut occasionnellement contenir des liens vers les
            sites internet de nos partenaires ou de sociétés tierces. Veuillez
            noter que ces sites internet ont leur propre politique de
            confidentialité et que nous déclinons toute responsabilité quant à
            l’utilisation faite par ces sites des informations collectées
            lorsque vous cliquez sur ces liens. Nous vous invitons à prendre
            connaissance de politiques de confidentialité de ces sites avant de
            leur transmettre vos Données Personnelles.
          </p>
        </Article>
        <Article title="10. Modification de notre Politique de Confidentialité">
          <p>
            Nous pouvons être amenés à modifier occasionnellement la présente
            Politique de Confidentialité. Lorsque cela est nécessaire, nous vous
            en informerons et/ou solliciterons votre accord. Nous vous
            conseillons de consulter régulièrement cette page pour prendre
            connaissance des éventuelles modifications ou mises à jour apportées
            à notre Politique de Confidentialité.
          </p>
        </Article>
        <Article title="11. Contact">
          <p>
            Pour toute question relative à la présente Politique de
            Confidentialité ou pour toute demande relative à vos Données
            Personnelles, vous pouvez nous contacter en adressant un email à
            notre service de contact à l’adresse{" "}
            <a href="mailto:contact@swun.fr">contact@swun.fr</a>
          </p>
        </Article>
      </Content>
    </>
  );
};

export default PrivacyPolicy;
