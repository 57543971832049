import { Link } from "react-router-dom";
import { Title } from "../../../components";

const SearchTrip = () => {
  return (
    <>
      <Title complement="Wiki Calshare" complementBeforeMainTitle>
        Rechercher un trajet
      </Title>

      <p>
        Une des principales fonctions de l'application Calshare est de pouvoir
        rejoindre des trajets.
      </p>
      <p>
        Lorsque tu arrives sur l'application, tu as directement sur la page
        d'accueil un champ où tu peux renseigner la destination souhaitée.
        <br />
        Une liste d'évènements est mise à ta disposition, tu peux en plus de
        cela écrire dans la barre de recherche pour obtenir des résultats selon
        ta recherche
        <br />
        Une fois que tu as trouvé l'évènement auquel tu souhaites te rendre, tu
        peux cliquer sur le bouton "y aller" pour valider ton itinéraire.
      </p>
      <p>
        Une fois la destination validée, tu es dirigé sur une page avec les
        trajets correspondants à ton itinéraire.
        <br />
        Tu peux à tout moment modifier ton point de départ et/ou ton point
        d'arrivée.
        <br />
        <mark>
          NOTE : Ta position actuelle est (lorsqu'elle est disponible)
          renseignée en tant que point de départ.
        </mark>
        <br />
        Tu peux alors voir les détails des trajets et cliquer sur ces derniers
        pour obtenir la fiche complète, sur laquelle tu peux notamment réserver
        ta place.
        <br />
        <mark>
          NOTE : Une authentification est requise pour réserver une place sur le
          trajet
        </mark>
      </p>
      <p>
        Tu peux par ailleurs ajouter un évènement en tant que favori, avec
        l'icone correspondante. Les évènements marqués comme favoris sont
        disponibles sur ton <Link to={"/calshare/wiki/profile"}>profil.</Link>
      </p>
    </>
  );
};

export default SearchTrip;
