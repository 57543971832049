import { Link } from "react-router-dom";
import { Title } from "../../../components";

const CreateAccount = () => {
  return (
    <>
      <Title complement="Wiki Calshare" complementBeforeMainTitle>
        Créer un compte
      </Title>

      <p>Pour effectuer certaines actions, incluant :</p>
      <ul>
        <li>Réserver une place sur un trajet</li>
        <li>Proposer un trajet</li>
        <li>Reçevoir des notifications</li>
        <li>Accéder au centre de notifications</li>
        <li>Accéder aux chats</li>
        <li>Accéder à la page de profil</li>
      </ul>
      <p>un compte est requis.</p>
      <p>
        Ce denier peut être créé facilement depuis la page "profil", sur
        complétion du formulaire d'authentification (si tu as déjà un compte) ou
        de cration (si tu n'as pas encore de compte).
      </p>
      <p>
        En soumettant le formulaire, vous pouvez rencontrer certaines erreurs,
        comme :
      </p>
      <ul>
        <li>
          <mark>Utilisateur introuvable</mark>
          <p>
            Cette erreur est affichée lorsque tu essayes de te connecter mais
            que l'addresse email renseignée ne pointe vers aucun compte
            existant.
          </p>
          <p>
            Vérifie si tu n'as pas fait une erreur d'écrite. Si ce n'est pas le
            cas, alors tu peux créer un compte en appuyant sur le texte
            correspondant.
          </p>
        </li>
        <li>
          <mark>Mot de passe incorrect</mark>
          <p>
            Cela signifie que le mot de passe entré ne correpond pas au mot de
            passe associé à ton compte.
          </p>
          <p>
            Pas de panique, si tu as oublié ton mot de passe, tu peux le
            réinitialiser en cliquant sur le texte "mot de passe oublié".
          </p>
        </li>
        <li>
          <mark>Veuillez remplir tous les champs</mark>
          <p>
            Si cette erreur apparaît, vérifie si tu as bien rempli tous les
            champs demandés.
          </p>
          <p>
            Si le problème persiste,{" "}
            <a href="mailto:contact@swun.fr">envoie-nous un mail</a>.
          </p>
        </li>
        <li>
          <mark>Ce nom d'utilisateur est déjà pris</mark>
          <p>
            Ce message s'affiche si, lors de la création de ton compte, tu
            renseignes un nom d'utilisateur qui est déjà associé à un autre
            compte.
          </p>
          <p>Tu est alors invité à modifier le nom d'utilisateur souhaité.</p>
        </li>
        <li>
          <mark>Cette addresse mail est déjà prise</mark>
          <p>
            Si tu vois ce message c'est que ton addresse mail est déjà associée
            à un compte.
          </p>
          <p>
            Tu est invité à te connecter via la page d'authentification en
            cliquant sur le texte "j'ai déjà un compte".
          </p>
          <p>
            Pas de panique, si tu as oublié ton mot de passe, tu peux le
            réinitialiser en cliquant sur le texte "mot de passe oublié".
          </p>
        </li>
        <li>
          <mark>Cette addresse mail est invalide</mark>
          <p>
            Cela signifie que l'addresse que tu as fournie est incorrecte, tu es
            invité à la modifier.
          </p>
          <p>
            Si tu penses que c'est un problème de la part de l'application,{" "}
            <a href="mailto:contact@swun.fr">envoie-nous un mail</a>.
          </p>
        </li>
        <li>
          <mark>Mot de passe trop faible</mark>
          <p>
            Cette erreur peut s'afficher si ton mot de passe ne correspond pas
            aux requis du système de création de compte.
          </p>
          <p>
            En effet, un mot de passe trop faible compromet la sécurité de ton
            compte.
          </p>
        </li>
      </ul>
      <br />
      <p>
        Pour voir quelles informations sont associées à votre profil, tu es
        invité à consulter la page{" "}
        <Link to="/calshare/wiki/profile">Profil.</Link>
      </p>
    </>
  );
};

export default CreateAccount;
